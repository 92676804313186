import { dew as _Dew } from ".";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.IS_READ_ONLY = void 0;
  const _1 = _Dew();
  exports.IS_READ_ONLY = true;
  function transformArguments(fromTimestamp, toTimestamp, filters, options) {
    return (0, _1.pushMRangeArguments)(["TS.MREVRANGE"], fromTimestamp, toTimestamp, filters, options);
  }
  exports.transformArguments = transformArguments;
  var _2 = _Dew();
  Object.defineProperty(exports, "transformReply", {
    enumerable: true,
    get: function () {
      return _2.transformMRangeReply;
    }
  });
  return exports;
}